<script>
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import { core } from "../../../config/pluginInit";
import CardTable from "@/components/cardTable/CardTable.vue";

export default {
  async mounted() {
    this.$isLoading(true);
    let persona = JSON.parse(localStorage.getItem("setPersonaAct"));

    if (persona.rol.nivel >= 4) {
      this.initData = await this.$store.dispatch("getVehiculosInspeccion");
    } else {
      let sucursalPersona = await this.$store.dispatch(
        "getSucursalPersona",
        persona.id
      );
      let { SucursalId } = sucursalPersona[0];
      if (SucursalId) {
        this.initData = await this.$store.dispatch(
          "getVehiculosInspeccion",
          SucursalId
        );
      }
    }

    setTimeout(() => {
      core.index();
      if (window.$.fn.DataTable.isDataTable("#datatable_inspeccionVehiculos")) {
        window.$("#datatable_inspeccionVehiculos").DataTable().destroy();
      }
      this.dataTableInstance = window
        .$("#datatable_inspeccionVehiculos")
        .DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
          },
        });
    }, 0);
    this.$isLoading(false);
  },
  components: {
    CardTable,
  },
  data() {
    return {
      initData: [],
      dataCards: [
        {
          title: "En proceso",
          value: () =>
            this.initData.filter(
              (item) =>
                item.EstadoUltimaInspeccion !== "Finalizada" &&
                item.EstadoUltimaInspeccion !== "Sin Inspecciones"
            ).length,
          iconName: "clock",
          color: "#1A5CFF",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("En proceso").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Finalizadas",
          value: () =>
            this.initData.filter(
              (item) =>
                item.EstadoUltimaInspeccion !== "En Proceso" &&
                item.EstadoUltimaInspeccion !== "Sin Inspecciones"
            ).length,
          iconName: "check-circle",
          color: "#17a76c",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Finalizada").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Sin inspecciones",
          value: () =>
            this.initData.filter(
              (item) =>
                item.EstadoUltimaInspeccion !== "Finalizada" &&
                item.EstadoUltimaInspeccion !== "En Proceso"
            ).length,
          iconName: "ban",
          color: "rgb(242, 19, 67)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Sin inspecciones").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Total inspecciones",
          value: () =>
            this.formatNumber(
              this.initData.reduce((acum, item) => {
                return acum + item.TotalInspecciones;
              }, 0)
            ),
          iconName: "file-signature",
          color: "rgb(0, 123, 146)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  methods: {
    // formato de cantidad ej: 1k, 2.5k ...
    formatNumber(num) {
      if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(1) + "k"; // Abreviación en miles
      } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M"; // Abreviación en millones
      }
      return num.toString(); // Si es menor a 1000, mostrar tal cual
    },
  },
  filters: {
    formatDateTime(dateString) {
      return dateString
        ? moment(dateString).format("MMM D, YYYY h:mm A")
        : "...";
    },
  },
};
</script>

<template>
  <HXContentCard title="Inspección de Vehículos">
    <div class="px-5 py-3">
      <div class="row" style="gap: 1rem">
        <div class="col">
          <!-- linar-style cursor glow -->
          <CardTable :data="dataCards" />
        </div>
      </div>
    </div>

    <div class="table-responsive px-4 mt-3">
      <table
        id="datatable_inspeccionVehiculos"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Placa</th>
            <th>Sucursal</th>
            <th>Total inspecciones</th>
            <th>Fecha inicio última inspeccion</th>
            <th>Fecha final última inspeccion</th>
            <th>Estado última inspeccion</th>
            <th>Visualizar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in initData" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.Placa }}</td>
            <td>{{ item.Sucursal }}</td>
            <td>{{ item.TotalInspecciones }}</td>
            <td>
              {{ item.FechaInicioUltimaInspeccion | formatDateTime }}
            </td>
            <td>
              {{ item.FechaFinalUltimaInspeccion | formatDateTime }}
            </td>
            <td>
              <h4>
                <b-badge
                  :variant="`${
                    item.EstadoUltimaInspeccion === 'Finalizada'
                      ? 'success'
                      : item.EstadoUltimaInspeccion === 'En Proceso'
                      ? 'primary'
                      : 'danger'
                  }`"
                >
                  <i
                    :class="`fas ${
                      item.EstadoUltimaInspeccion === 'Finalizada'
                        ? 'fa-check'
                        : item.EstadoUltimaInspeccion === 'En Proceso'
                        ? 'fa-clock'
                        : 'fa-ban'
                    }`"
                  ></i
                  >&nbsp;
                  {{ item.EstadoUltimaInspeccion }}
                </b-badge>
              </h4>
            </td>
            <td>
              <vs-button
                v-b-tooltip.hover
                title="Ver inspecciones"
                danger
                class="mx-auto"
                @click="
                  $router.push({
                    name: 'ListadoInspecciones',
                    params: {
                      placa: item.Placa.toLowerCase(),
                      vehiculoid: item.VehiculoId,
                    },
                  })
                "
              >
                <i class="fas fa-eye"></i
              ></vs-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
