var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Inspección de Vehículos"}},[_c('div',{staticClass:"px-5 py-3"},[_c('div',{staticClass:"row",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"col"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1)])]),_c('div',{staticClass:"table-responsive px-4 mt-3"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_inspeccionVehiculos"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Placa")]),_c('th',[_vm._v("Sucursal")]),_c('th',[_vm._v("Total inspecciones")]),_c('th',[_vm._v("Fecha inicio última inspeccion")]),_c('th',[_vm._v("Fecha final última inspeccion")]),_c('th',[_vm._v("Estado última inspeccion")]),_c('th',[_vm._v("Visualizar")])])]),_c('tbody',_vm._l((_vm.initData),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.Placa))]),_c('td',[_vm._v(_vm._s(item.Sucursal))]),_c('td',[_vm._v(_vm._s(item.TotalInspecciones))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.FechaInicioUltimaInspeccion))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.FechaFinalUltimaInspeccion))+" ")]),_c('td',[_c('h4',[_c('b-badge',{attrs:{"variant":`${
                  item.EstadoUltimaInspeccion === 'Finalizada'
                    ? 'success'
                    : item.EstadoUltimaInspeccion === 'En Proceso'
                    ? 'primary'
                    : 'danger'
                }`}},[_c('i',{class:`fas ${
                    item.EstadoUltimaInspeccion === 'Finalizada'
                      ? 'fa-check'
                      : item.EstadoUltimaInspeccion === 'En Proceso'
                      ? 'fa-clock'
                      : 'fa-ban'
                  }`}),_vm._v("  "+_vm._s(item.EstadoUltimaInspeccion)+" ")])],1)]),_c('td',[_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-auto",attrs:{"title":"Ver inspecciones","danger":""},on:{"click":function($event){_vm.$router.push({
                  name: 'ListadoInspecciones',
                  params: {
                    placa: item.Placa.toLowerCase(),
                    vehiculoid: item.VehiculoId,
                  },
                })}}},[_c('i',{staticClass:"fas fa-eye"})])],1)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }